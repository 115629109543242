/* Devroldan */
import { AfterViewInit, Inject, EventEmitter, ElementRef, Input, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { ListsSandbox } from '../../../../core/lists/lists.sandbox';
//import { ListsSandbox } from '../../../../core/lists/lists.sandbox';
import { ConfigService } from '../../../../core/service/config.service';
//import { ConfigService } from '../../../../core/service/config.service';
import { Subscription, Observable } from 'rxjs';
import { MatDialog, MAT_DRAWER_DEFAULT_AUTOSIZE_FACTORY } from '@angular/material';
import { CommonSandbox } from 'src/core/common/common.sandbox';
import { Store } from '@ngrx/store';
import { AppState } from 'src/core/state.interface';
import {
  ObtenerDireccionesAction,
  AgregarDireccionAction,
  EliminarDireccionAction,
  GuardarImagenAction,
  SubirImagenDisenioAction, 
  AgregarAdjuntoImagenDisenioAction,
  EliminarAdjuntoDisenioAction,
  AgregarTextoDisenioAction,
  AgregarLogoDisenioAction,
  AgregarReferenciaDisenioAction
} from 'src/core/clientes/action/acciones';

import { DireccionDialog } from './ventanas-emergentes/direcciones/dialog-direccion.component';
import { CargaDisenioDialog } from './ventanas-emergentes/carga-imagen-disenio/carga-disenio.component';
import { ConfirmacionDialog } from './ventanas-emergentes/confirmacion/confirmacion.component';
import { ProductControlSandbox } from 'src/core/product-control/product-control.sandbox';
import { GetProfile } from 'src/core/common/action/common.action';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BreakPointRegistry } from '@angular/flex-layout';
import { map, startWith } from 'rxjs/operators';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';




@Component({
  selector: 'app-producto',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent2 implements OnInit, OnDestroy, AfterViewInit {
  // decorator
  @ViewChild('zoomViewer') zoomViewer;
  @ViewChild('fileInput') fileInput;
  @ViewChild('stepper') stepper;
  @ViewChild('precioDinamico') precioDinamicoElement: ElementRef;


  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  @Input() idProductoCarrito;
  @Input() indiceCarrito;
  @Input() productoCounteo;
  @Input() dataFormulario;
  @Input() idCliente;
  @Input() idOrden;
  @Output()
  cotizacion = new EventEmitter<any>();

  actualizarOrdenOCotizacion(){
    let indexSeleccionado = 2;

    //let dialogos=_CargaDisenioDialog_|_DireccionDialog_;
    const dialogRef = this.dialog.open(
      this.arrayConfirmaciones[indexSeleccionado].type,
      this.arrayConfirmaciones[indexSeleccionado].params
    );
    this.subscriptions.push(
      this.subsDialog = dialogRef.afterClosed().subscribe(this.arrayConfirmaciones[indexSeleccionado].subscribe)
    );
  }

  confirmarAgregarCarrito(): void {
    let indexSeleccionado = 2;

    //let dialogos=_CargaDisenioDialog_|_DireccionDialog_;
    const dialogRef = this.dialog.open(
      this.arrayConfirmaciones[indexSeleccionado].type,
      this.arrayConfirmaciones[indexSeleccionado].params
    );
    this.subscriptions.push(
      this.subsDialog = dialogRef.afterClosed().subscribe(this.arrayConfirmaciones[indexSeleccionado].subscribe)
    );
  }

  // configuration
  public config: SwiperConfigInterface = {};
  public config2: SwiperConfigInterface = {};
  public configuration: SwiperConfigInterface = {};
  public product: any;
  //Está en ventana emergente
  public estaEnVentanaEmergente=false;
  // images
  public image: any;
  public zoomImage: any;
  public imagePath: string;
  public productImageId: number;
  // route params
  private sub: any;
  private id: any;
  private cliente_id: string;
  private perfil_id: string;
  // available options
  public templateHidden: number;
  public cantidad: number;
  public unidad_medida: string;
  public nombre_unidad: string;

  public unidad_medida_label1: string;
  public unidad_medida_label2: string;

  public direcciones$;
  public disenioImagen$;
  public adjuntosDisenioImagen$;
  public direccionesSeleccionadas = [];

  public clientes: any[] = [];
  public clienteSeleccionado: any = null;
  public clienteSeleccionado_id: any = null;
  public perfilSeleccionado: any = null;
  public actualizarPer: boolean = false;

  perfilesFacturacion: any[];
  tipoSeleccionado:    any='Contacto';
  labelSeleccionado:   any="Solicitantes";


  public opcionDisenioEscojida = 0;

  // subcription
  private subscriptions: Array<Subscription> = [];
  isActive = [];
  yaPuedeActualizarPreciosEditar: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public productDetail: ListsSandbox,
    public controlSandbox: ProductControlSandbox,
    public commonSandbox: CommonSandbox,
    private configService: ConfigService,
    private changeDetectRef: ChangeDetectorRef,
    private appState$: Store<AppState>,
    private router: Router,
    private http: HttpClient,
  ) {
    window["productRef2"] = this;
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = true;
        window.scrollTo(0, 0);
      }

    });

    console.log("yuyup x 89");


  }

  abrirClienteContacto=false;
  tipoClienteContacto:string="";
  hijo:boolean=true;
  padre:any=null;

  ventanaClienteContacto(par){
    this.abrirClienteContacto=par;
  }

  agregarUnContacto(){
    this.hijo=false;
    this.padre=false;
    this.tipoClienteContacto="contacto";
    this.ventanaClienteContacto(true);
  }
  
  agregarUnCliente(){
    this.hijo=false;
    this.padre=false;
    this.tipoClienteContacto="cliente";
    this.ventanaClienteContacto(true);
  }

  agregarHijo(){
    this.padre=this.clienteSeleccionado;
    this.ventanaClienteContacto(true);
    this.hijo=true;
  }

  entidadAgregada(res){
    this.abrirClienteContacto=false;
    if(res.union){
      if(res.padre.tipo=="contacto"){
        this.perfilesFacturacion.unshift(res.hijo);
        this.perfilSeleccionado=this.perfilesFacturacion[0].id;
        this.cambiaGenerico('perfil');
      }else if(res.padre.tipo=="cliente"){
        this.perfilesFacturacion.unshift(res.hijo);
        this.perfilSeleccionado=this.perfilesFacturacion[0].id;
        this.cambiaGenerico('perfil');

      }
    }else{
      
      if(!res.hijo){
        this.options.unshift(res.datos);
        this.clientes.unshift(res.datos);
        this.clienteSeleccionado = this.clientes[0];
        this.cambiaGenerico('cliente');
      }else{
        //this.options.unshift(res.datos);
        this.perfilesFacturacion.unshift(res.datos);
        this.perfilSeleccionado = this.perfilesFacturacion[0].id;
        this.cambiaGenerico('perfil');
      }
    }

  }

  

  adaptarParaImpresionDigital() {
    return [627,663].indexOf(parseFloat(this.id)) > -1 ? true : false;
  }

  calcularPiezas(){
    //32 x 46
    let ancho1= parseInt((32/this.ancho).toString()) ;
    let alto1=parseInt((46/this.alto).toString()) ;
    let res1= parseFloat((ancho1*alto1).toString()).toFixed(2);
    
    let ancho2= parseInt((46/this.ancho).toString()) ;
    let alto2=parseInt((32/this.alto).toString()) ;
    let res2= parseFloat((ancho2*alto2).toString()).toFixed(2);

    return res1>res2?res1:res2;
  }

  removerFlotante(param){
    return param.toString().replace(/\..*/g,"");
  }



  userRol: string = "cliente";
  dataUser: any = {};
  clienteAux = "";


  /** Initially initialize getProductdetail,getRelatedProducts when subscribed
   * subscribe productDetails$ and set initially default values for required options **/
  ngOnInit() {
    
    console.log("ngOnInit");
    let _cliente_id = localStorage.getItem("cliente_id") || "";
    let _perfil_id = localStorage.getItem("perfil_id") || "";
    this.clienteAux = JSON.parse(localStorage.getItem("cliente_seleccionado") || "[]");
    setTimeout(() => {
      if (this.clienteAux && this.clienteAux.length == 1) {        
        this.clienteSeleccionado = this.clienteAux[0];
      }
      this.changeDetectRef.detectChanges();
    }, 3000);

    this.appState$.dispatch(new ObtenerDireccionesAction(466));
    this.imagePath = this.configService.getImageUrl();
    // subscribe route params and trigger selected product detail, related products
    this.sub = this.activatedRoute.params.subscribe(params => {
      let getProductoR=()=>{
        if(!params["id"] || this.idProductoCarrito){

          this.estaEnVentanaEmergente=true;

        }
        return params["id"]||this.idProductoCarrito;
      };
      this.id=getProductoR();
      //getProductoR();
      this.getProductdetail();
      this.getBannerList();
    });
    this._ngOnInit();
    this.direcciones$ = this.appState$.select('direcciones');
    this.disenioImagen$ = this.appState$.select('imagenDisenio');

    this.subscriptions.push(
      this.disenioImagen$.subscribe(data => {
        setTimeout(() => {
          this.changeDetectRef.detectChanges();
        }, 100)
      })
    );

    this.adjuntosDisenioImagen$ = this.appState$.select('adjuntosDisenioImagen');
    this.subscriptions.push(
      this.adjuntosDisenioImagen$.subscribe((_data) => {
        //console.log("ADJUNTOS",_data);
        if (_data)
          this.nArchivos = _data.length;
      })
    );

    this.subscriptions.push(
      this.commonSandbox.getProfile$.subscribe(data => {
        if (data) {
          //this.cliente_id=data.id;
          this.dataUser = JSON.parse(localStorage.getItem("user"));
          //console.log("data:user",this.dataUser)
          //this.getProductdetail();
          if (!this.dataUser["vendedor_id"])
            this.appState$.dispatch(new ObtenerDireccionesAction(this.cliente_id));
          else {
            this.clientes = this.dataUser.clientes;
            this.changeDetectRef.detectChanges();
          }
          this.actualizarPer = false;
          setTimeout(() => {
            this.actualizarPer = true;
            this.perfilSeleccionado = parseFloat(_perfil_id || "0");
            this.changeDetectRef.detectChanges();
          }, 3000)
          //this.perfilSeleccionado=_perfil_id;
          if (_cliente_id) {

            this.clienteSeleccionado_id = _cliente_id;

            for (let x = 0; x < this.clientes.length; x++) {
              if (this.clienteSeleccionado_id == this.clientes[x].id) {
                this.clienteSeleccionado = this.clientes[x];

                if(this.clienteSeleccionado.tipo=="contacto"){
                  this.perfilesFacturacion = this.clienteSeleccionado["perfiles"];
                  this.tipoSeleccionado="contacto";
                  this.labelSeleccionado="Clientes"
                } else{//cliente
                  this.perfilesFacturacion = this.clienteSeleccionado["clientes"];
                  this.tipoSeleccionado="cliente";
                  this.labelSeleccionado="Solicitantes"
                }
                //this.perfilesFacturacion = this.clienteSeleccionado["perfiles"];


                this.changeDetectRef.detectChanges();

              }
            }


          }



        }
      })
    );

    this.subscriptions.push(
      this.appState$.select('direcciones').subscribe(data => {
        var aux = this.clonarArray(this.direccionesSeleccionadas);
        this.buscarSelectores(aux, data);
        this.direccionesSeleccionadas = data;
        this.changeDetectRef.detectChanges();
      })
    );


  }

  options: ContactoModelo[] = [

  ];


  /*


  */


  control_autocompletable = new FormControl();
  filteredOptions: Observable<ContactoModelo[]>;

  private _filter(_contacto: string): ContactoModelo[] {
    const filterValue = _contacto.toLowerCase();

    return this.options.filter(option => option.firstName.toLowerCase().includes(filterValue));
  }

  displayFn(_contacto: ContactoModelo): string {
    return _contacto && _contacto.firstName ? _contacto.firstName : '';
  }

  temporalConctactoValor: any;

  cargandoBusquedaContacto: boolean = false;


  buscarContactos() {
    var pal = this.temporalConctactoValor;
    console.log("control de value", this.control_autocompletable.value.name);
    console.log("control de value", this.temporalConctactoValor);
    this.cargandoBusquedaContacto = true;


    this.http.post(this.getBaseUrl() + "customer/buscar-clientes", { texto_busqueda: pal, c: "sdfklsdj22343423sklfjasfASDFhfjasdf2#$F#423423" }).toPromise()
      .then((data: any) => {

        console.log("la respuesta", data);
        this.options = data.data;
        this.clientes = data.data;this.options = data.data;
        this.clientes = data.data;
        if (this.clientes && this.clientes.length > 0) {
          this.clienteSeleccionado = this.clientes[0];
          this.cambiaGenerico('cliente');
          setTimeout(() => {
            if (this.perfilesFacturacion && this.perfilesFacturacion.length > 0) {
              this.perfilSeleccionado = this.perfilesFacturacion[0].id;

            }


          }, 500);
        }

        this.cargandoBusquedaContacto = false;
      })
      .catch(e => {
        this.cargandoBusquedaContacto = false;
      });
  }

  buscarSelectores(aux, data) {
    for (var j = 0; j < aux.length; j++) {
      for (var i = 0; i < data.length; i++) {
        if (aux[j].id == data[i].id && aux[j].selected) {
          //console.log("Encontrao!!!!!!!!!!!!")
          data[i].selected = true;
        }
      }
    }
  }
  clonarArray(arr) {
    let arre = [];
    for (var i = 0; i < arr.length; i++) {
      arre.push(Object.assign(arr[i], {}));
    }
    return arre;
  }

  abrirConfirmacion() {

  }

  private subsDialog: any;
  abrirDireccion(data): void {
    data.editImages = false;
    const dialogRef = this.dialog.open(DireccionDialog, {
      width: '520px',
      height: '430px',
      data
    });
    this.subscriptions.push(
      this.subsDialog = dialogRef.afterClosed().subscribe(result => {
        //console.log("resultado de dialogo",result)
        if (result) {
          if (result.id) {
            this.appState$.dispatch(new EliminarDireccionAction(result))
          } else {

            result["cliente_id"] = this.cliente_id;
            this.appState$.dispatch(new AgregarDireccionAction(result))
          }
        }
      })
    );
  }
  dispara = new EventEmitter<any>();
  abrirDireccionAddImage(data, indice): void {
    data.editImages = true;
    data.eventE = this.dispara;
    var _objx: Observable<any>;
    this.subscriptions.push(
      this.appState$.select('direcciones').subscribe(_data => {
        data = _data[indice];
        this.dispara.emit(_data[indice])
      }
      ));

    const tamDialog = window.innerWidth >= 1015 ? 1000 : window.innerWidth - 20;

    const dialogRef = this.dialog.open(DireccionDialog, {
      width: tamDialog + 'px',
      height: '500px',
      data
    });
    this.subsDialog = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.id) {
          this.appState$.dispatch(new EliminarDireccionAction(result))
        } else {

          result["cliente_id"] = this.cliente_id;
          this.appState$.dispatch(new AgregarDireccionAction(result))
        }
      }
    });
  }


  ocultarAcabado: any = false;

  // fetch banner list from sandbox
  getBannerList() {
    const params: any = {};
    params.limit = 100;
    params.offset = 0;
    this.productDetail.getBannerList(params);
  }
  unidadesMedida: any[] = [{ unidad: 'metros' }, { unidad: 'centimetros' }];
  campos: any = [];
  verx: any;
  detalle: any;
  precioOriginal: any;
  precioUnitario: any;
  /**
   * calls productDetail getProductDetails with param.Then subscribe productDetail productDetails$
   *
   * Then store the image path  and image name in the array.
   * **/

  getProductdetail() {
    const params: any = {};
    params.id = this.idProductoCarrito||this.id;
    params.cliente_id = this.cliente_id;
    if (localStorage.getItem("cliente_id")) {
      this.cliente_id = localStorage.getItem("cliente_id") || "0";
    } else {
      localStorage.setItem("cliente_id", this.cliente_id || "0");
    }

  /*   console.log(`MI ID PRODUCTO: ${params.id}`); */

    this.productDetail.getProductDetails(params);
    this.subscriptions.push(
      this.productDetail.productDetails$.subscribe(detail => {
        /* console.log("EL PRODUCTO", detail); */

        if (detail && detail.productImage.length > 0) {
          this.detalle = JSON.parse(JSON.stringify(detail));
          window["detalleP"] = this.detalle;
          //console.log("cae esto")

          if (this.detalle["descuentos"]["campos"])
            this.detalle["descuentos"]["campos"] = JSON.parse(this.detalle["descuentos"]["campos"]);
          //console.log("campos",this.detalle);

          this.detalle["subtotal"] = detail.price;
          this.precioOriginal = detail.price;
          this.precioUnitario = detail.price;
          this.campos = this.detalle.productFieldDev;
          //zzconsole.log("*////////////////////")
          //zzconsole.log(detail);

          setTimeout(() => {
            this.productDetail.productDetails$["price"] = 1000;
            this.changeDetectRef.detectChanges();
            //zzconsole.log("cambiado")
            //zzconsole.log(this.productDetail.productDetails$);

          }, 6000);
          for (var i = 0; i < this.campos.length; i++) {
            //zzconsole.log("el nombre",this.campos[i]["nombre"]);
            this.campos[i]["opciones_o"] = JSON.parse(this.campos[i]["opciones"]);
            this.campos[i]["opciones"] = this.buscarDescuentos(this.campos[i]["nombre"], i);
            //this.campos[i]["opciones"]=JSON.parse(this.campos[i]["opciones"]||"[]");
          }

          let medidasProducto = {
            largo: this.detalle.medida_largo,
            alto: this.detalle.medida_alto
          };

          this._medidaAl = parseFloat(medidasProducto.alto.split(".")[0] || 1);
          this._medida2Al = parseFloat(medidasProducto.alto.split(".")[1] || 0);
          this._medidaAn = parseFloat(medidasProducto.largo.split(".")[0] || 1);
          this._medida2An = parseFloat(medidasProducto.largo.split(".")[1] || 0);
          this.nombre_unidad = this.detalle.nombre_unidad || '1';
          this.unidad_medida = this.detalle.unidad_medida;
          switch (this.unidad_medida) {
            case "m":
              this.unidad_medida_label1 = "Metros";
              this.unidad_medida_label2 = "Centrimetros";
              break;
            case "cm":
              this.unidad_medida_label1 = "Centrimetros";
              this.unidad_medida_label2 = "Milimetros";
              break;
            case "pul":
              this.unidad_medida_label1 = "Pulgadas";
              this.unidad_medida_label2 = "Decima de P.";
              break;
            default:

              this.unidad_medida_label1 = "sin u";
              this.unidad_medida_label2 = "sin u";
              break;
          }

          window["campos"] = this.campos;
          window["ver"] = detail;
          this.verx = detail;
          setTimeout(() => {
            this.changeDetectRef.detectChanges();
          }, 2000);
          this.crearFormulario();
          const imageLength = detail.productImage.length;
          this.isActive = [];
          this.isActive[detail.productImage[0].productId] = true;
          for (let i = 0; i < imageLength; i++) {
            if (detail.productImage[i].defaultImage === 1) {
              this.productImageId = detail.productImage[0].productId;
              this.image =
                this.imagePath + '?path=' +
                detail.productImage[i].containerName + '&name=' +
                detail.productImage[i].image +
                '&width=390&height=390';
              this.zoomImage =
                this.imagePath + '?path=' +
                detail.productImage[i].containerName + '&name=' +
                detail.productImage[i].image +
                '&width=660&height=660';
              this.changeDetectRef.detectChanges();
              setTimeout(() => {
                this.config.observer = true;
                //this.config2.observer = true;
                this.changeDetectRef.detectChanges();
              });
            }
          }
          this.cambiaMedida();
         
          if(this.dataFormulario){
             let idC=setInterval(()=>{
              if(!this.yaPuedeActualizarPreciosEditar){
                console.log("consultand...");
                return;
              }
              clearInterval(idC);//
              
              if(this.productoCounteo!=0){
                document["cambiaCantidad"](this.productoCounteo,this);
                this.cantidad=this.productoCounteo;
                this.detalle.productCount=this.cantidad; 
                console.log(this.productoCounteo); 
                console.log("/*****************************/");

              }
             
            /*  
              document["cambiaCantidad"](this.productoCounteo,this);
              
              //console.log(this.dataFormulario);
              this.cantidad=this.productoCounteo;
              this.detalle.productCount=this.cantidad; */
              let fnUnirData=()=>{
                let parseados=[];
                this.dataFormulario.forEach(element => {
                  let nombre=element.nombre.toLowerCase();
                  nombre=nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                  nombre=nombre.replace(/\ /g,"_");
                  if(nombre!="medidas")
                    parseados.push({'nombre':nombre,value:element.value});
                  if(this.detalle["medida_fija"]=="variable" && element.nombre=="Medidas"){
                    let medidas=element.value.replace(/(\ |\n|Largo|Alto|m)/g,"");
                    
                    let largo1=medidas.split("x")[0].split(".")[0];
                    this._medidaAn=parseFloat(largo1);
                    let largo2=medidas.split("x")[0].split(".")[1];
                    this._medida2An=parseFloat(largo2);

                    let alto1=medidas.split("x")[1].split(".")[0];
                    this._medidaAl=parseFloat(alto1);
                    let alto2=medidas.split("x")[1].split(".")[1];
                    this._medida2Al=parseFloat(alto2);
                    this.cambiaMedida();

                    console.log(`Medida encontrada`,medidas);
                  }
                });

                parseados.forEach(element=>{
                  vare1:for(let clave in this.productoForm.controls){
                    this.campos.forEach((el2,elInd) => {
                      if(clave.toLowerCase()==element.nombre && el2.nombre.toLowerCase()==element.nombre){
                        switch(el2.tipo){
                          case 'Múltiple':
                            let valorFinal=[];
                            let valorFinalTexto=[];
                            let valoresABuscar=element.value.split(",");
                            valoresABuscar.forEach(elValor => {
                              //console.log(`buscando..${elValor}`)
                              el2.opciones.forEach(opValue => {
                                //console.log(`Comparando(${elValor},${opValue.texto})==${elValor.trim()==opValue.texto.trim()}`)
                                if(elValor.trim()==opValue.texto.trim()){
                                  valorFinal.push(opValue.valor_agregado);
                                  valorFinalTexto.push(opValue.texto);
                                }
                              });
                            });
                            console.log("los valores en textro",valorFinalTexto);
                            document.getElementById("nombre_"+clave+elInd)["value"]=JSON.stringify(valorFinalTexto);
                            this.productoForm.controls[clave].setValue(JSON.stringify(valorFinal));



                          ;break;
                          default:
                            this.productoForm.controls[clave].setValue(element.value);
                        }
                      }
                      
                    });

                  }
                });
                

                  this.changeDetectRef.detectChanges();
              
                /* console.log("los nuevos valores",parseados); */
              }
              if(this.productoCounteo!=0)
                fnUnirData();

            },300);
          }
        }
      })
    );
  }

  protected getBaseUrl(): string {
    return environment.storeUrl;
  }

  public obtenerDescuentosCliente(params?: any): Observable<any> {
    let url = this.getBaseUrl();
    const reqOpts: any = {};
    /* if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    } */
    return this.http.get(url + 'product-store/producto-descuento/' + this.id + '/' + this.cliente_id + '', reqOpts);
  }

  public obtenerDescuentosPerfil_y_Grupo(params?: any): Observable<any> {
    let url = this.getBaseUrl();
    const reqOpts: any = {};
    /* if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (k) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    } */
    return this.http.get(url + 'product-store/producto-descuento-perfil-y-grupal/' + this.id + '/' + this.perfilSeleccionado + '', reqOpts);
  }

  buscarDescuentos(descuento, indice) {
    ////zzconsole.log("buscandooooooooooooooooooo")
    var descuentos = this.detalle["descuentos_originales"] || [];
    ////zzconsole.log("####",descuentos.length)
    for (var j = 0; j < descuentos.length; j++) {
      if (descuentos[j]["nombre"] == descuento) {
        //zzconsole.log(descuento+"====",descuentos[j]["nombre"])
        //console.log("00000",this.detalle["descuentos"]["campos"][j]["opciones"]);
        if (descuento != "Descuento por volumen") {



          let deees;
          if(this.detalle["descuentos"]["campos"][j].tipo!="Descuento"){
            
            deees = JSON.parse(JSON.stringify(this.detalle["descuentos"]["campos"][j]["opciones"]));
          }else{
            deees=[];
          }
          //console.log("comensando..." + descuento + ":", deees);
          deees.forEach((element, indice) => {
            //console.log(`2222222${element.texto} && ${this.detalle["descuentos"]["campos"][j]["opciones"][indice].valor_agregado} && ${element.valor_agregado.indexOf(",")}>-1`);
            if (element &&
              descuentos[j]["opciones"][indice].valor_agregado &&
              descuentos[j]["opciones"][indice].valor_agregado.indexOf(",") > -1
            ) {
              //let formulasDeOpcion=this.detalle["descuentos"]["campos"][j]["opciones"][indice].valor_agregado.split(",");
              let formulasDeOpcion = descuentos[j]["opciones"][indice].valor_agregado.split(",");
              //revisar si tiene formula
              //console.log("1si pasa...wewrjweklfjslkdfjsdklfj", element.valor_agregado);

              formulasDeOpcion.forEach((element2, indice2) => {
                if (element2.indexOf(">") > -1) {
                  let formulita = element2.split(">");
                  let form1 = formulita[1].trim().split(" ");
                  let formCantidad = form1[0];
                  let formValor = form1[1];
                  if (this.detalle["productCount"] > parseFloat(formCantidad)) {
                    console.log("si pasa...wewrjweklfjslkdfjsdklfj")
                    deees[indice]["valor_agregado"] = formValor;
                  }
                  //console.log(`Cantidad:${formCantidad}, Valor:${formValor}`);
                } else {

                  deees[indice]["valor_agregado"] = element2;
                }
              });
            }
          });
          //console.log(`retornando`, deees);
          return deees;
        }
        return this.detalle["descuentos"]["campos"][j]["opciones"];
      }
    }

    try {

      return JSON.parse(this.campos[indice]["opciones"] || "[]")
    } catch (error) {
      return this.campos[indice]["opciones"]

    }

  }

  stringIfi(str){
    return JSON.stringify(str);
  }

  descuentos_combinaciones:any[]=[];
  cambiaGenerico(entidad: string) {
    //console.log("cambiando cliente");
    if(this.clienteSeleccionado.tipo=="contacto"){
      this.perfilesFacturacion = this.clienteSeleccionado["perfiles"];
      this.tipoSeleccionado="contacto";
      this.labelSeleccionado="Clientes"
      this.tipoClienteContacto="contacto";
    } else{//cliente
      this.perfilesFacturacion = this.clienteSeleccionado["clientes"];
      this.tipoSeleccionado="cliente";
      this.tipoClienteContacto="cliente";
      this.labelSeleccionado="Solicitantes"
    }
    this.cliente_id = this.clienteSeleccionado_id = this.clienteSeleccionado.id;
    this.perfil_id = this.perfilSeleccionado;
    localStorage.setItem("cliente_seleccionado", JSON.stringify([this.clienteSeleccionado]));
    localStorage.setItem("cliente_id", this.cliente_id);
    localStorage.setItem("perfil_id", this.perfil_id);
    //console.log("guardandoCliente!")actualizarPer
    localStorage.setItem("cliente_data", JSON.stringify(this.clienteSeleccionado));
    //this.appState$.dispatch(new ObtenerDireccionesAction(this.cliente_id));
    //this.appState$.dispatch(new GetProfile());
    this.subscriptions.push(
      this[entidad == "cliente" ? "obtenerDescuentosCliente" : "obtenerDescuentosPerfil_y_Grupo"]().subscribe(data => {
        console.log("recibiendo descuentos:",data["data"]); 
        this.yaPuedeActualizarPreciosEditar=true;
        if (data["data"] && (data["data"]["campos"]||data["data"].length>0)) {
          //this.detalle["descuentos"]["campos"]=JSON.parse(data["data"]["campos"]); 
          
          let fnD=(descuentoParam)=>{

            let descuentos = this.detalle["descuentos"]["campos"] || [];
            let camposD = (descuentoParam);
            if (entidad == "cliente") {
              descuentos = [];
            } else {



              for (var i = 0; i < descuentos.length; i++) {
                if(descuentos[i].tipoD==camposD[0].tipoD){
                  descuentos.splice(i, 1);
                  i = i - 1;

                }
                /* if (["perfil","grupo"].indexOf(descuentos[i].tipoD)!=-1) {
                } */
              }
            }
            for (let i = 0; i < camposD.length; i++) {
              if(entidad=="cliente")
                camposD[i].tipoD = entidad;
              descuentos.push(camposD[i]);
            }
            console.log(`Descuentos agregados`,descuentos);

            //if()

            console.log("los descuentos por aqui:",camposD);

            for (let i = 0; i < descuentos.length; i++) {
              for (let j = 0; j < camposD.length; j++) {
                if (descuentos[i].nombre == camposD[j].nombre && descuentos[i].tipo!="Descuento") {

                /*   for (let op = 0; op < descuentos[i].opciones.length; op++) {
                    
                    let valor1 = descuentos[i].opciones[op].valor_agregado;//parseFloat(descuentos[i].opciones[op].valor_agregado.replace(/\D/g, ""))
                    let valor2 = camposD[j].opciones[op].valor_agregado;//parseFloat(camposD[j].opciones[op].valor_agregado.replace(/\D/g, ""))
                    if (valor2 < valor1) { 
                    
                    }
                  } */ 
                }
              }
            }
            this.detalle["descuentos"]["campos"] = descuentos;
            this.detalle["descuentos_originales"] = JSON.parse(JSON.stringify(descuentos));


            for (var i = 0; i < this.campos.length; i++) {
              //zzconsole.log("el nombre",this.campos[i]["nombre"]);
              //this.campos[i]["opciones_o"]=JSON.parse(this.campos[i]["opciones"]);
              this.campos[i]["opciones"] = this.buscarDescuentos(this.campos[i]["nombre"], i);
              /* for(let xx=0;xx<this.campos[i]["opciones"].length;xx++){
                var campoActual=this.campos[i]["opciones"]
              } */
              //this.campos[i]["opciones"]=JSON.parse(this.campos[i]["opciones"]||"[]");
            }
          }
          if(data["data"].length>0){
            let removerLuego=["perfil","grupo"];

            data["data"].forEach(elm => {
              let devCampos=JSON.parse(elm["campos"||"[]"])||[];
              if(devCampos.length>0 && removerLuego.indexOf(devCampos[0].tipoD)!=-1)
              {
                  removerLuego=removerLuego.filter(e=>e!=devCampos[0].tipoD);
              }
              fnD(devCampos);
            });

            removerLuego.forEach(el=>{
              let descuentos = this.detalle["descuentos"]["campos"] || [];
              for (let i = 0; i < descuentos.length; i++) {
                if(descuentos[i].tipoD==el){
                  descuentos.splice(i,1);
                  i=i-1;
                }
                
              }
              this.detalle["descuentos"]["campos"]=descuentos;
            });

          }else{
            fnD(JSON.parse(data["data"]["campos"]));
          }
          this.cambiaMedida();
          this.revisarPrecioBase();
          this.changeDetectRef.detectChanges();
        } else {
          let descuentos=this.detalle["descuentos"]["campos"] || [];
          for (let i = 0; i < descuentos.length; i++) {
            if(entidad=="cliente" && descuentos[i].tipoD){
                descuentos.splice(i,1);      
                i=i-1;

            }else if(["perfil","grupo"].indexOf(descuentos[i].tipoD)!=-1){
                descuentos.splice(i,1);
                i=i-1;
            }
          }
          for (var i = 0; i < this.campos.length; i++) {
            this.campos[i]["opciones"] = JSON.parse(JSON.stringify(this.campos[i]["opciones_o"]));
          }
          if (this.detalle["descuentos"]["campos"])
            for (var i = 0; i < this.detalle["descuentos"]["campos"].length; i++) {
              if (this.detalle["descuentos"]["campos"][i].tipoD == entidad) {
                this.detalle["descuentos"]["campos"].splice(i, 1);
                i = i - 1;
              }
            }
          // this.detalle["descuentos"]["campos"]=[];
          this.cambiaMedida();
          this.revisarPrecioBase();

        }
        this.crearGridDeDescuento();
      })
    );
  }

  

  agregarEncargadoAlCliente(){

  }
  agregarClienteAlEncargado(){

  }

  cambiaCliente() {
    //console.log("cambiando cliente");
    //this.perfilesFacturacion = this.clienteSeleccionado["perfiles"];
    if(this.clienteSeleccionado.tipo=="contacto"){
      this.perfilesFacturacion = this.clienteSeleccionado["perfiles"];
      this.tipoSeleccionado="contacto";
      this.labelSeleccionado="Cliente"
    } else{//cliente
      this.perfilesFacturacion = this.clienteSeleccionado["clientes"];
      this.tipoSeleccionado="cliente";
      this.labelSeleccionado="Solicitantes"
    }
    this.cliente_id = this.clienteSeleccionado_id = this.clienteSeleccionado.id;
    localStorage.setItem("cliente_id", this.cliente_id);
    localStorage.setItem("cliente_data", JSON.stringify(this.clienteSeleccionado));
    //this.appState$.dispatch(new ObtenerDireccionesAction(this.cliente_id));
    //this.appState$.dispatch(new GetProfile());
    this.subscriptions.push(
      this.obtenerDescuentosCliente().subscribe(data => {
        //console.log("recibiendo descuentos:",data["data"]);
        if (data["data"] && data["data"]["campos"]) {
          //this.detalle["descuentos"]["campos"]=JSON.parse(data["data"]["campos"]); 
          let descuentos = this.detalle["descuentos"]["campos"] || [];
          let camposD = JSON.parse(data["data"]["campos"]);
          for (var i = 0; i < descuentos.length; i++) {
            if (descuentos[i].tipoD == "cliente") {
              descuentos.splice(i, 1);
            }
          }
          for (let i = 0; i < camposD.length; i++) {
            camposD[i].tipoD = "cliente";
            descuentos.push(camposD[i]);
          }

          for (let i = 0; i < descuentos.length; i++) {
            for (let j = 0; j < camposD.length; j++) {
              if (descuentos[i].nombre == camposD[j].nombre) {

                for (let op = 0; op < descuentos[i].opciones.length; op++) {
                  // camposD[j]["opciones"]=JSON.parse(camposD[j]["opciones"]);
                  let valor1 = parseFloat(descuentos[i].opciones[op].valor_agregado.replace(/\D/g, ""))
                  let valor2 = parseFloat(camposD[j].opciones[op].valor_agregado.replace(/\D/g, ""))
                  if (valor2 < valor1) {
                    descuentos[i].opciones[op].valor_agregado = camposD[j].opciones[op].valor_agregado;
                  }
                }
              }
            }
          }
          this.detalle["descuentos"]["campos"] = descuentos;


          for (var i = 0; i < this.campos.length; i++) {
            //zzconsole.log("el nombre",this.campos[i]["nombre"]);
            //this.campos[i]["opciones_o"]=JSON.parse(this.campos[i]["opciones"]);
            this.campos[i]["opciones"] = this.buscarDescuentos(this.campos[i]["nombre"], i);
            /* for(let xx=0;xx<this.campos[i]["opciones"].length;xx++){
              var campoActual=this.campos[i]["opciones"]
            } */
            //this.campos[i]["opciones"]=JSON.parse(this.campos[i]["opciones"]||"[]");
          }
          this.cambiaMedida();
          this.revisarPrecioBase();
          this.changeDetectRef.detectChanges();
        } else {
          for (var i = 0; i < this.campos.length; i++) {
            this.campos[i]["opciones"] = JSON.parse(JSON.stringify(this.campos[i]["opciones_o"]));
          }
          this.detalle["descuentos"]["campos"] = [];
          this.cambiaMedida();
          this.revisarPrecioBase();
          this.changeDetectRef.detectChanges();
        }
      })
    );
  }

  cambiaPerfil() {
    //console.log("cambiando perfil");
    this.perfilesFacturacion = this.clienteSeleccionado["perfiles"];
    this.cliente_id = this.clienteSeleccionado_id = this.clienteSeleccionado.id;
    localStorage.setItem("cliente_id", this.cliente_id);
    localStorage.setItem("cliente_data", JSON.stringify(this.clienteSeleccionado));
    //this.appState$.dispatch(new ObtenerDireccionesAction(this.cliente_id));
    //this.appState$.dispatch(new GetProfile());
    this.obtenerDescuentosPerfil_y_Grupo().subscribe(data => {
      if (data["data"] && data["data"]["campos"]) {
        this.detalle["descuentos"]["campos"] = JSON.parse(data["data"]["campos"]);

        for (var i = 0; i < this.campos.length; i++) {
          //zzconsole.log("el nombre",this.campos[i]["nombre"]);
          //this.campos[i]["opciones_o"]=JSON.parse(this.campos[i]["opciones"]);
          this.campos[i]["opciones"] = this.buscarDescuentos(this.campos[i]["nombre"], i);
          //this.campos[i]["opciones"]=JSON.parse(this.campos[i]["opciones"]||"[]");
        }
        this.cambiaMedida();
        this.revisarPrecioBase();
          this.changeDetectRef.detectChanges();
      } else {
        for (var i = 0; i < this.campos.length; i++) {
          this.campos[i]["opciones"] = JSON.parse(JSON.stringify(this.campos[i]["opciones_o"]));
        }
        this.detalle["descuentos"]["campos"] = [];
        this.cambiaMedida();
        this.revisarPrecioBase();
          this.changeDetectRef.detectChanges();
      }
    });
  }
  cambiaCantidad(event) {
    this.cantidad = event;
    this.detalle["productCount"] = this.cantidad;
    this.productoForm.controls["cantidad"].setValue(this.cantidad);
    this.calcularPrecio();
  }
  traeDescuentos = false;
  ngAfterViewInit() {
    console.log("ngAfterViewInit");
    this.getProductdetail();

    let ii = setInterval(() => {
      if (this.detalle && this.clienteSeleccionado && this.detalle.descuentos) {
        this.perfilSeleccionado = localStorage.getItem("perfil_id") || '';
        this.cambiaGenerico('cliente');
        //let _perfil=localStorage.getItem("perfil_id");
        //console.log("valor seleccionado", this.perfilSeleccionado);
        clearInterval(ii);
      }
    }, 300)
    this.config = {
      observer: false,
      direction: 'vertical',
      autoHeight: true,
      autoplay: true,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      grabCursor: true,
      pagination: false,
      loop: false,
      preloadImages: false,
      lazy: true,
      watchSlidesVisibility: true,
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3
        }
      }

    };
    this.config2 = {
      slidesPerView: 3,
      spaceBetween: 50,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      /* autoplay: {
        delay: 5000,
        disableOnInteraction: false
      }, */
      autoplay: false,
      speed: 500,
      effect: 'slide',
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        480: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3
        },
        960: {
          slidesPerView: 4
        },
        1280: {
          slidesPerView: 5
        },
        1500: {
          slidesPerView: 6
        }
      }
    };
    this.configuration = {
      observer: false,
      slidesPerView: 4,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      grabCursor: true,
      pagination: false,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: false,
      watchSlidesVisibility: true,
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3
        }
      }
    };
  }

  // view the selected image
  public selectImage(image, i) {
    this.productImageId = image.productId;
    this.isActive = [];
    this.isActive[image.productId] = true;
    this.image =
      this.imagePath + '?path=' +
      image.containerName + '&name=' +
      image.image +
      '&width=390&height=390';
    this.changeDetectRef.detectChanges();
    this.zoomImage =
      this.imagePath + '?path=' +
      image.containerName + '&name=' +
      image.image +
      '&width=660&height=660';
  }

  // zoom the image when mouse got moved over the image
  public onMouseMove(e) {
    if (window.innerWidth >= 1280) {
      let image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = (offsetX / image.offsetWidth) * 100;
      y = (offsetY / image.offsetHeight) * 100;
      if (this.zoomImage) {
        zoomer = this.zoomViewer.nativeElement.children[0];
      }
      if (zoomer) {
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.display = 'block';
        zoomer.style.height = image.height + 'px';
        zoomer.style.width = image.width + 'px';
      }
    }
  }

  // event when mouse clicked to zoom the image
  public onMouseLeave(event) {
    this.zoomViewer.nativeElement.children[0].style.display = 'none';
  }

  // open pop to view the zoom image
  public openZoomViewer() {
    this.dialog.open(ProductZoomComponent, {
      data: this.zoomImage,
      panelClass: 'zoom-dialog'
    });
  }

  // unsubscribe subscribed events while destroy the page
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.subscriptions.forEach(each => {
      each.unsubscribe();
    });
    if (this.subsDialog)
      this.subsDialog.unsubscribe();
    this.clienteSeleccionado = null;

  }
  public productoForm: FormGroup;
  disenoForm: FormGroup;



  crearFormulario() {
    console.log("llamndo form")
    let _group = {}
    this.campos.forEach(($valor, $indice) => {
      /* //zzconsole.log("valor:",$valor);
      //zzconsole.log("indice:",$indice); */
      if ($valor.tipo == "Único")
        _group[$valor["nombre"]] = new FormControl($valor["opciones"][0]["valor_selecionado"]);
      else
        _group[$valor["nombre"]] = new FormControl('');
    });
    _group["_medidaAn"] = new FormControl('');
    _group["_medida2An"] = new FormControl('');
    _group["_medidaAl"] = new FormControl('');
    _group["_medida2Al"] = new FormControl('');
    _group["cantidad"] = new FormControl('1');
    _group["control_autocompletable"] = this.control_autocompletable;

    this.productoForm = new FormGroup(_group);
    this.disenoForm = new FormGroup({});

    this.filteredOptions = this.control_autocompletable.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
    window["productoForm"] = this.productoForm;
  }

  visible($campo: any) {

    ////zzconsole.log("entrando...")
    if ($campo.visualizacion) {
      let _retorno = true;
      let _json = JSON.parse($campo.visualizacion);
      _json.forEach($element => {
        for (let _key in $element) {
          let _operacion = eval(('\'' + this.productoForm.get(_key).value + '\'' || '\'\'') + "" + $element[_key]);
          ////zzconsole.log("calculo:",('\''+this.productoForm.get(_key).value+'\''||'\'\'')+""+$element[_key])
          try {
            ////zzconsole.log("operacion",(_operacion));
            if (!_operacion) _retorno = false;
          } catch (error) {
            //console.error(error);
            _retorno = false;
          }
        }
      });
      ////zzconsole.log("retornando",_retorno)
      return _retorno;

    } else {
      return true;
    }
  }

  productoFormFn() {

  }



  public buscarPrecioCampo(nombreCampo) {
    for (var i = 0; i < this.campos.length; i++) {
      if (this.campos[i].nombre == nombreCampo) {
        let indiceSeleccionado = 0;
        let valueS = this.productoForm.get(nombreCampo).value;
        if (valueS) {
          for (let j = 0; j < this.campos[i].opciones.length; j++) {
            if (this.campos[i].opciones[j].valor_selecionado == valueS) {
              return this.campos[i].opciones[j].valor_agregado;
            }
          }
        } else {
          return this.campos[i].opciones[0].valor_agregado;
        }
      }
    }
    return "";
  }

  formatearAgregado(nombreCampo) {
    for (var i = 0; i < this.campos.length; i++) {
      if (this.campos[i].nombre == nombreCampo) {
        let indiceSeleccionado = 0;
        let valueS = this.productoForm.get(nombreCampo).value;
        if (valueS) {
          for (let j = 0; j < this.campos[i].opciones.length; j++) {
            if (this.campos[i].opciones[j].valor_selecionado == valueS) {

              return "" + (("" + parseFloat(this.campos[i].opciones[j].valor_agregado).toFixed(2)))

            }
          }
        }
      }
    }
    return "";



  }
  /*
 
  */

  revisarPrecioBase() {
    if (!this.detalle["productCount"]) this.detalle["productCount"] = 1;
    //console.log("detallazo!!!!!!!!!")
    //console.log(this.detalle)
    
    let precioProd = this.precioOriginal;
    let totalm2: number =
    parseFloat(this.fixearCantidad(this.ancho * this.alto)) *
    (this.detalle["productCount"]);
    //alert(this.detalle["productCount"]+":"+totalm2)
    //console.log("Precio original",precioProd)
    const campos = this.detalle["descuentos"]["campos"] || [];
    console.log("devroldan campos",campos)
    let coincidenciaEncontrada = false;
    let pasa = false;
    for (let i = 0; i < campos.length; i++) {
      if(campos[i].tipo=="Descuento")continue;
      let formula;
      //this.buscarDescuentos(this.campos[i]["nombre"], i);


      if (campos[i].tipo == "Formula") {
        pasa = true;
        //m2 > 25 entonces precio_producto= 100
        formula = campos[i]["opciones"][0].valor_agregado + ":null";
        //remplazo m2
        formula = formula.replace(/m2/g, totalm2);
        //remplazo entonces
        formula = formula.replace(/entonces/g, "?");

        //remplazo precio del producto
        formula = formula.replace(/precio_producto/g, "precioProd");

        //console.log("the formula:",formula);
        //console.log("the resultado:",precioProd);
        //TODO separa los tipos de formula, pero es este caso se puden juntar las 2

        try {

          eval(formula)
        } catch (error) {
          formula = formula.replace(/:null/g, "");
          //console.log("the formula:",formula);
          //console.log("the resultado:",precioProd);
          eval(formula)
        }
        this.precioUnitario = precioProd;
        this.changeDetectRef.detectChanges();
      } else if (campos[i].tipo == "precio_combinado") {
        console.log("precio combinadidisisimo");
        let opciones = campos[i]["opciones"];
        let coincide = true;
        for (let j = 0; j < opciones.length; j++) {
          if(!opciones[j])continue;
          let nombreCampo = opciones[j]["nombre"];
          let valorSeleccionado = opciones[j]["valor_selecionado"];
          if( opciones[j]["tipo"]=="Múltiple"){
            let conteo=0;
            for (let _xx in this.productoForm.controls){
              conteo++;
              let _indice=_xx + (conteo - 1);
              let elem=document.getElementById(`nombre_${_indice}`)
              if (elem){
                let _ops = JSON.parse(document.getElementById(`nombre_${_indice}`)["value"] || "[]");
                _ops = _ops.map((_op) => _op.toLowerCase());
                let _encontrado = false;
                valorSeleccionado.forEach((_op)=>{
                  if(!_ops.includes(_op)){
                    coincide = false;
                  }
                })
              }
              
            }
              let arrayAComparar = opciones[j]["valor_selecionado"];
            
          }
          else if (this.productoForm.controls[nombreCampo].value != valorSeleccionado) {
            coincide = false;
            break;
          }
        }
        if (coincide) {
          alert("coincide");
          coincidenciaEncontrada=true;
          this.precioUnitario = parseFloat(campos[i]["precio"]);
          this.changeDetectRef.detectChanges();
        }
      }else if (campos[i]["nombre"] != "Descuento por volumen") {
        campos[i]["opciones"] = this.buscarDescuentos(campos[i]["nombre"], i);
        //console.log(`Dando el descuento que le toca`, campos[i]["opciones"]);
        for (let i2 = 0; i2 < this.campos.length; i2++) {
          if (this.campos[i2].nombre == campos[i].nombre) {
            //this.campos[i2].opciones=campos[i].opciones; 

          }
        }
        for (var ii = 0; ii < this.campos.length; ii++) {

          //  this.campos[ii]["opciones"] = this.buscarDescuentos(this.campos[ii]["nombre"], ii);
          //this.campos[ii]["opciones"];
          if (this.campos[ii].nombre == campos[i].nombre) {
            

            for (let xx = 0; xx < this.campos[ii]["opciones"].length; xx++) {
              this.campos[ii]["opciones"][xx]["valor_agregado"] = campos[i]["opciones"][xx]["valor_agregado"];
            }
          }
        }
        this.changeDetectRef.detectChanges();
        //console.log("el campo con descuento.....2323232", campos[i])
        // this["campos"][i]=campos[i];
        // console.log("dando aqui we...7777",campos[i]);
        /*   for (var iee = 0; iee < this.campos.length; iee++) {
          
           this.campos[iee]["opciones"] = this.buscarDescuentos(this.campos[iee]["nombre"], i);
         
         } */

      }
    }
    this.coincide=coincidenciaEncontrada;
    if (!pasa && !coincidenciaEncontrada) {
      this.precioUnitario = precioProd;
    }
    this.changeDetectRef.detectChanges();
  }
  //m2 > 0 20, m2 > 10 15, m2 > 20 13, m2 > 50 10, m2 > 100 9
  //m2 > 0 35, m2 > 10 26, m2 > 20 23, m2 > 50 16, m2 > 100 13
  
  //totalm2 > 0 20, totalm2 > 10 15, totalm2 > 20 13, totalm2 > 50 10, totalm2 > 100 9
  //totalm2 > 0 35, totalm2 > 10 26, totalm2 > 20 23, totalm2 > 50 16, totalm2 > 100 13

  //Suaje, Suajado
  _obtenerFormulaDescuento(str):number{
    let _formulas=str.split(",");
    let valor_retorno=0;
    console.log(`str:${str}`);
    _formulas.forEach((element,index) => {
      if(element.indexOf("m2")>-1){
        let totalm2: number =
        parseFloat(this.fixearCantidad(this.ancho * this.alto));
        let cantidad  = element.split(">")[1].trim().split(" ")[0];
        let precio    = element.split(">")[1].trim().split(" ")[1];
        if(totalm2> parseFloat(cantidad))
          valor_retorno=parseFloat(precio);
      }
      if(element.indexOf("totalm2")>-1){
        let totalm2: number =
        parseFloat(this.fixearCantidad(this.ancho * this.alto))* (this.detalle["productCount"]);
        let cantidad  = element.split(">")[1].trim().split(" ")[0];
        let precio    = element.split(">")[1].trim().split(" ")[1];
        if(totalm2> parseFloat(cantidad))
          valor_retorno=parseFloat(precio);
      }
      if(element.indexOf("n")>-1){
        let cantidadN: number =
        parseFloat(this.fixearCantidad(1))* (this.detalle["productCount"]);
        let cantidad  = element.split(">")[1].trim().split(" ")[0];
        let precio    = element.split(">")[1].trim().split(" ")[1];
        if(cantidadN> parseFloat(cantidad))
          valor_retorno=parseFloat(precio);
      }
    });
    //console.log("dando el valor de:",valor_retorno)

    return valor_retorno;
  }

  coincide=false;
  precioFinalDescuento=0;
  calcularPrecio() {
    if (!this.detalle["productCount"]) this.detalle["productCount"] = 1;
    this.revisarPrecioBase();
    let precioBaseUnitario = this.precioUnitario;
    let _operaciones = parseFloat(this.precioUnitario.toString());
   // console.log(`1111---${this.precioUnitario} * ${this.detalle["productCount"]} * ${totalm2};`)
    
    //console.log("precio unitario:",precioBaseUnitario)
    //alert("12=>"+_price)
    let conteo = 0;
    let multiplicador = 1;
    let aux_cantidad = parseFloat(this.detalle["minima_cantidad"]);
    if (1 / this.detalle["productCount"] > 1)
      multiplicador = multiplicador * (1 / this.detalle["productCount"]);
    else if (multiplicador)
      multiplicador = 1;

    let redondearSobrante = false;
    let precioUnitarioSinDescuento = this.precioUnitario;
    let agregadoAlFinal = 0;

    let camposDescuentosValidos     =0;
    let camposDescuentosEncontrados =0;
    let precioFinalDescuento        =0;


    let tieneSobrante = this.detalle["productCount"] % 1 == 0 ? false : true;
    //revisar otras formulas
    if((this.detalle["descuentos"]["campos"]||[]).length>0){
      this.detalle["descuentos"]["campos"].forEach(element => {
        if(element.tipo=="Descuento"){
          let camposDescuentosValidos     =0; 
          let camposDescuentosEncontrados =0;

          element.campos.forEach(elementCampo => {
            //console.log("revisando...",elementCampo.nombre)
            let _dNombre=elementCampo.nombre.toLowerCase().replace(/\ /gi,"_");
            _dNombre=_dNombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let _dValor=elementCampo.value;

            let formCampo=this.productoForm.controls[_dNombre];
            let campoNN=this.buscarCampo(_dNombre);
            //console.log("campo",campoNN);
            if(formCampo){
              if(campoNN.tipo=="Múltiple" && _dValor=="Ninguno"){
                

                _dValor="";
              }
              camposDescuentosValidos++;
              let valorActualCampo=formCampo.value;
              if(campoNN.tipo=="Múltiple" ){
                let indice=this.buscarCampoIndice(_dNombre);
                valorActualCampo=document.getElementById(`nombre_${_dNombre}${indice}`)["value"];
                valorActualCampo=JSON.parse(valorActualCampo||"[]").join(",");
                //console.log(`Indice multiple:${indice}`,valorActualCampo);
              }
              //console.log(`${_dNombre}::${_dValor}==${valorActualCampo}`);   

              if(_dValor==valorActualCampo){
                camposDescuentosEncontrados++;
                //console.log(`Nombre:${_dNombre}, Valor:${_dValor}`); 
              }

            }
            //console.log("--")
            //console.log("--")
          });
          //console.log(`${camposDescuentosEncontrados}==${camposDescuentosValidos}`);
          if(camposDescuentosEncontrados==camposDescuentosValidos
          
            ){

            if(this.precioFinalDescuento!=0){
              precioFinalDescuento=element.precioFinal;
              this.precioFinalDescuento=precioFinalDescuento<this.precioFinalDescuento?
              precioFinalDescuento:this.precioFinalDescuento;
            }else{
              precioFinalDescuento=element.precioFinal;
              this.precioFinalDescuento=precioFinalDescuento;
            }
          }
        }
      });
    }

    
    if(!this.coincide)
    for (let _x in this.productoForm.controls) {
      conteo++;
      ////zzconsole.log(_x)
      ////zzconsole.log(this.productoForm.controls[_x].value);
      let _campo = this.buscarCampo(_x);
      //console.log("controlProducto",this.productoFormprecio unitar.controls[_x])
      //console.log("calculando precio!!")
     // console.log(`${_operaciones}__${_campo["tipo"]}__${_x}`);
      switch (_campo["tipo"]) {
        case 'Único':
          if(precioFinalDescuento>0){//Si hay un descuento seleccionable
            break;
          }
          let _opcionesC = _campo['opciones'];
          for (var j = 0; j < _opcionesC.length; j++) {
            //console.loc()


            if (!_opcionesC[j]["valor_agregado"]) {
              continue;
            } 


            ////zzconsole.log(jj[j]["valor_selecionado"]+"=="+this.productoForm.controls[_x].value)
            //console.log(_opcionesC[j]["valor_selecionado"]+"=="+this.productoForm.controls[_x].value)
            let _oper = _operaciones;
            if (_opcionesC[j]["valor_selecionado"] == this.productoForm.controls[_x].value) {
              if (_opcionesC[j]["valor_agregado"].split(",").length != 1) {
                _oper+=this._obtenerFormulaDescuento(_opcionesC[j]["valor_agregado"]);
              } else {


                switch (this._obtenerOperacion(_opcionesC[j]["valor_agregado"])) {
                  case "suma": ;
                  case "resta":
                    //_operaciones=`((${_operaciones})+(${_opcionesC[j]["valor_agregado"]}))`;
                    _oper += parseFloat(_opcionesC[j]["valor_agregado"].toString());
                    break;
                  case "multiplicacion":
                    _oper *= parseFloat(_opcionesC[j]["valor_agregado"].toString()
                      .replace(/\**|x*|\ */g, "")
                    );
                    break;
                  case "porcentaje":
                    _oper *= 1 + parseFloat(_opcionesC[j]["valor_agregado"].toString()
                      .replace(/\%|\ $/g, "")
                    ) / 100;
                    ;
                }
              }

              if (_opcionesC[j]["largo"] && _opcionesC[j]["alto"]) {
                this.extraerMedidas(_opcionesC[j]["largo"], _opcionesC[j]["alto"])

              }

              _oper = _oper - _operaciones;
              _oper *= multiplicador;
              _operaciones += _oper;
              precioUnitarioSinDescuento += _oper;
              ////zzconsole.log("sssssssssssssssss")
            }
          }
          ; break;
        case 'Múltiple':
          if(precioFinalDescuento>0){
            break;
          }
          let agregarAlFinal = false;
          try {
            let _getJson = JSON.parse(_campo["valor"]);
            agregarAlFinal = _getJson["agregarAlFinal"] || false;
          } catch (error) {

          }


          let opciones = [];
          if (document.getElementById(_x + (conteo - 1)))
            opciones = JSON.parse(document.getElementById(_x + (conteo - 1))["value"] || "[]");
          //console.log("lllllllll",_x+(conteo-1));
          //console.log(_x+(conteo-1),opciones);


          ////zzconsole.log(jj[j]["valor_selecionado"]+"=="+this.productoForm.controls[_x].value)
          //console.log(_opcionesC2[j]["valor_selecionado"]+"=="+this.productoForm.controls[_x].value)

          let _oper = _operaciones;
          for (let mi = 0; mi < opciones.length; mi++) {
            if (opciones[mi].split(",").length != 1) {
              console.log(`Dando:${this._obtenerFormulaDescuento(opciones[mi])}`);
              let rest=parseFloat(this._obtenerFormulaDescuento(opciones[mi]).toString());
              if (!agregarAlFinal)
                _oper+=rest;
              else
                agregadoAlFinal +=rest;
              
            } else {
              
              switch (this._obtenerOperacion(opciones[mi])) {
                case "suma": ;
                case "resta":
                  let opee = parseFloat(opciones[mi].toString());
                  if (!agregarAlFinal)
                  _oper += opee;
                  else
                  agregadoAlFinal += opee;
                break;
                case "multiplicacion":
                  let opee2 = parseFloat(opciones[mi].toString()
                  .replace(/\**|x*|\ */g, ""));
                  if (!agregarAlFinal) {
                    _oper *= opee2;
                  } else {
                    agregadoAlFinal += opee;
                  }
                  break;
                  case "porcentaje":
                    let opee3 = 1 + parseFloat(opciones[mi].toString()
                    .replace(/\%|\ $/g, "")) / 100;
                    _oper *= opee3;
                    
                    ;
              }
            }
           


          }
          _oper = _oper - _operaciones;
          _oper *= multiplicador;
          _operaciones += _oper;
          precioUnitarioSinDescuento += _oper;

          ; break;
        case 'DescuentoXVolumen':
          if(precioFinalDescuento>0){
            break;
          }

          let aux_operaciones = _operaciones;

          try {
            if (!redondearSobrante) {
              let _getJson = JSON.parse(_campo["valor"]);
              redondearSobrante = _getJson["redondearSobrante"] || false;
            }
          } catch (error) {

          }
          
          let xxxx = 1 / 0;

          if (this.detalle.descuentos.campos && this.detalle.descuentos.campos.length)
            for (let i = 0; i < this.detalle.descuentos.campos.length; i++) {
              let valArray = this.detalle.descuentos.campos[i];
              valArray["opciones"][0].valor_agregado = valArray["opciones"][0].valor_agregado.toLowerCase();
              switch (valArray["tipo"]) {
                case "DescuentoXVolumen":
                  let cantidad = this.cantidad || 1;
                  let condicionalCantidad = valArray["opciones"][0].valor_agregado.split(">=")[1].trim().split(" ")[0];
                  let campo_c = "";
                  let campo_v = "";
                  let ope = "";
                  let form_value = "";
                  if (valArray["opciones"][0].valor_agregado.search(/\ si\ /g) + 1) {
                    campo_c = valArray["opciones"][0].valor_agregado.split('\ si\ ')[1];
                    //campo_c=campo_c.replace(/\ /g,'');
                    ope = campo_c.search(/\!\=/g) + 1 > 0 ? '!=' : "=";
                    //console.log("el ope",valArray["opciones"][0].valor_agregado)
                    campo_v = campo_c.split(ope)[1].replace(/\ /g, '');
                    campo_c = campo_c.split(ope)[0].replace(/(^ *)|(\ *$)/g, '').replace(/ /g, '_');
                    //console.log("el campo",campo_c)
                    form_value = (this.productoForm.controls[campo_c].value.toString()).toLowerCase().replace(/\ /g, '');
                  }


                  if (
                    parseFloat(cantidad.toString()) >= parseFloat(condicionalCantidad)
                  ) {

                    if (!campo_c) {
                      _operaciones = aux_operaciones;
                      let descuento = 1 - parseFloat(valArray["opciones"][0].valor_agregado.split(">=")[1].trim().split(" ")[1].replace(/\%/g, "")) / 100;
                      _operaciones = _operaciones * descuento;
                    } else if (campo_c) {

                      if (ope == "!=" && form_value != campo_v) {
                        console.log(`campo:${campo_c} ${this.productoForm.controls[campo_c].value}______`)
                        _operaciones = aux_operaciones;
                        let descuento = 1 - parseFloat(valArray["opciones"][0].valor_agregado.split(">=")[1].trim().split(" ")[1].replace(/\%/g, "")) / 100;

                        _operaciones = _operaciones * descuento;
                      }


                      if (ope == "=" && form_value == campo_v) {
                        _operaciones = aux_operaciones;
                        let descuento = 1 - parseFloat(valArray["opciones"][0].valor_agregado.split(">=")[1].trim().split(" ")[1].replace(/\%/g, "")) / 100;

                        _operaciones = _operaciones * descuento;
                      }

                    }
                    //alert(_operaciones);

                  }
                  break;
              }

            }
          break;
          case 'precio_combinado':
        let opcionesCombinadas = this.detalle["descuentos"]["campos"].find(campo => campo.tipo === 'precio_combinado');
        if (opcionesCombinadas) {
          let coincide = true;
          for (let j = 0; j < opcionesCombinadas.opciones.length; j++) {
            let nombreCampo = opcionesCombinadas.opciones[j]["nombre"];
            let valorSeleccionado = opcionesCombinadas.opciones[j]["valor_selecionado"];
            if (this.productoForm.controls[nombreCampo].value != valorSeleccionado) {
              coincide = false;
              break;
            }
          }
          if (coincide) {
            alert("coincide");
            this.precioUnitario = parseFloat(opcionesCombinadas["precio"]);
            this.changeDetectRef.detectChanges();
          }
        }
        break;
      }

    }
   
    this.precioUnitario = precioFinalDescuento==0? Math.round(_operaciones):precioFinalDescuento;
    //this.precioUnitario = precioFinalDescuento==0? (_operaciones):precioFinalDescuento;
    let totalm2 = parseFloat(this.fixearCantidad(this.ancho * this.alto));

    if (this.detalle.medida_fija == 'fija' || this.detalle.ignorar_medida_metrica=="1") {
      totalm2 = 1;
    }
    //_operaciones=parseFloat(""+(parseFloat(eval(_operaciosnes))*this.detalle["productCount"])).toFixed(2);
    //_operaciones=parseFloat(eval(_operaciones)).toFixed(2);


    
    //_operaciones=(parseFloat(_operaciones)*this.cantidad)+"";
    //zzconsole.log("operaciones",_operaciones);
    let sobrante = this.detalle["productCount"] % 1;
    if (this.detalle["productCount"] > 1)
      this.detalle["productCount"] = this.detalle["productCount"] - sobrante;
    console.log(`${this.precioUnitario} * ${this.detalle["productCount"]} * ${totalm2};`)
    this.detalle["subtotal"] = this.precioUnitario * this.detalle["productCount"] * totalm2;
    if (tieneSobrante && redondearSobrante && this.detalle["productCount"] >= 1) {
      //console.log(`(${precioUnitarioSinDescuento}*${aux_cantidad})*(${aux_cantidad}/(${sobrante}))`)
      this.detalle["subtotal"] += (precioUnitarioSinDescuento * aux_cantidad) * ((sobrante) / aux_cantidad);
    }
    this.detalle["subtotal"] += agregadoAlFinal;
     //this.detalle["subtotal"]=this.parseoParaFactura(this.detalle["subtotal"]);
    //this.detalle.descuentoT=(this.precioOriginal*this.detalle["productCount"]* totalm2)-(precioBaseUnitario* this.detalle["productCount"]* totalm2);
    this.detalle.descuentT = this.detalle.descuentT || 0;
  }

  revisarCheckeo(str:string,id:string,valor:string){
    id='nombre_'+id;
    if(!document.getElementById(id))
      return false;

    let elementValue=document.getElementById(id)["value"]||"[]";
    let x:any[]=JSON.parse(elementValue);
    if(x.indexOf(str)!=-1){
      //console.log(`str:${str},id:${id},valor:${valor}`);
      //console.log(`chekeo:${elementValue}`);
      return true;
    }else{ 
      return false;
    }
   // console.log("valor del elemento checkbox",elementValue);
  }

  cambiaOpcionMultiple(checked, opcionTexto, id, ops) {

    
    let opciones = JSON.parse(document.getElementById(id)["value"] || "[]");
    let opcionesN = JSON.parse(document.getElementById("nombre_" + id)["value"] || "[]");
    if (!checked) {

      for (let i = 0; i < opcionesN.length; i++) {
        //console.log(`${opcionTexto}==,${opciones[i].texto}`);
        if (opcionTexto == opcionesN[i]) {
          opcionesN.splice(i, 1);
          opciones.splice(i, 1);

        }
      }
    } else { 

      opciones.push(ops);
      opcionesN.push(opcionTexto)
    }
    document.getElementById(id)["value"] = JSON.stringify(opciones);
    document.getElementById(`nombre_${id}`)["value"] = JSON.stringify(opcionesN);
    this.calcularPrecio();
  }

  obtenerValoresdeCampoMultiplo(nombre_campo, indice) {
    let opciones = [];
    if (document.getElementById(`nombre_${nombre_campo + indice}`)) {
      try {
        opciones = JSON.parse(document.getElementById(`nombre_${nombre_campo + indice}`)["value"]);

      } catch (error) {
        opciones = [];
      }
    }
    let cad = "";
    for (let i = 0; i < opciones.length; i++) {
      cad = cad + opciones[i] + ", ";
    }
    if(opciones.length==0){
      cad="Ninguno";
      return cad;
    }else
      return cad.substr(0, cad.length - 2);
  }
  descuentosCliente    :any[]=[];
  descuentosPerfil     :any[]=[];
  descuentosGrupo      :any[]=[];

  mostrarClientes=false;
  mostrarPerfiles=false;
  mostrarGrupos=false;

  imprimirConsole(){
    console.log("consola tonta");
  }

  mostrarV(str,val){
    console.log(`${str}:${val}`);
    switch(str){
      case "cliente":
        this.mostrarClientes = val;  
      break;
      case "perfil":
        this.mostrarPerfiles = val;
      break;
      case "grupo":
        this.mostrarGrupos   = val;
      break;
    }
  }

  gridDeDescuento:any[]=[];

  crearGridDeDescuento(){
    this.descuentosCliente =[];
    this.descuentosPerfil  =[];
    this.descuentosGrupo   =[];
    
    if(!this.detalle || !this.detalle.descuentos || !this.detalle.descuentos["campos"])
      return [];

    /* if(!this.detalle.descuentos.campos)
      return [] */

    let descuentos=this.detalle.descuentos;

    //console.log("979w874892374892374892374238947923",this.detalle.descuentos) 
    descuentos["campos"].forEach(descuentoEl => {
      /* if(descuentoEl.tipo=="Descuento"){ */
        switch(descuentoEl.tipoD){
          case "cliente"  : 
          this.descuentosCliente.push(descuentoEl);
          break;
          case "perfil"   : 
          this.descuentosPerfil.push(descuentoEl);
          break;
          case "grupo"    : 
          this.descuentosGrupo.push(descuentoEl);
          break;
        }
      /* }else{

      } */
    });

    let result=[];

    if(this.descuentosCliente.length>0){
      result.push({
        tipoD:"cliente",
        descuentos:this.descuentosCliente
      });
    }
    if(this.descuentosPerfil.length>0){

      result.push({
        tipoD:"perfil",
        descuentos:this.descuentosPerfil
      });
    }
    if(this.descuentosGrupo.length>0){

      result.push({
        tipoD:"grupo",
        descuentos:this.descuentosGrupo
      })
    }

    this.gridDeDescuento=result;
    
  }

  buscarPrecioOriginal(strTitulo, strOpcion,obj){
    //console.log(`strTitulo, strOpcion::${strTitulo}, ${strOpcion}`,obj);
    if(!this.detalle || !this.detalle.descuentos)
      return "";
    for(let x=0;x<this.campos.length;x++){
      if(this.campos[x].titulo==strTitulo){
        if(this.campos[x].opciones){

          for(let j=0;j<this.campos[x].opciones_o.length;j++){
            if(this.campos[x].opciones_o[j].texto==strOpcion){
              return this.campos[x].opciones_o[j].valor_agregado;
            }
          }
        }
      }
    }
    return "";
  }

  filtrarDescuentosVisuales(descuentos){
    //console.log("Descuentos visuales---",descuentos);
    let cadRespuesta="";
    let arrCad=[];
    let descartados=[
      "Observaciones","observaciones","Medidas","Medidas"
    ];
    descuentos.forEach(element => {
      if(descartados.indexOf(element.nombre)==-1){
        arrCad.push(` ${element.nombre}:<B>${element.value}</B>`);
      }
    });
    return arrCad.join(",");
  }


  _obtenerOperacion(str: any): string {

    let cad = str.toString().trim();

    if (new RegExp(/^\*/g).test(cad)) {
      return "multiplicacion";
    } else if (new RegExp(/\%$/g).test(cad)) {
      return "porcentaje";
    } else if (new RegExp(/^\//g).test(cad)) {
      return "division"
    } else if (new RegExp(/^\-/g).test(cad)) {
      return "resta"
    }


    return "suma";
  }
  /**
   * 
   * @param medidaA alto
   * @param medidaL ancho
   */
  extraerMedidas(medidaL, medidaA) {


    let medidasProducto = {
      largo: parseFloat(medidaL),
      alto: parseFloat(medidaA)
    };
    this.ancho = medidasProducto.largo;
    this.alto = medidasProducto.alto;
    //console.log("extrayendo medidas",medidasProducto); 


    this._medidaAl = parseFloat(medidasProducto.alto.toString().split(".")[0]) || 1;
    this._medida2Al = parseFloat(medidasProducto.alto.toString().split(".")[1]) || 0;
    this._medidaAn = parseFloat(medidasProducto.largo.toString().split(".")[0]) || 1;
    this._medida2An = parseFloat(medidasProducto.largo.toString().split(".")[1]) || 0;

    this.calcularAnchoFN();
    this.calcularAltoFN();
    this.changeDetectRef.detectChanges();
  }

  fixearCantidad(_value) {
    return parseFloat(_value + "").toFixed(2);
  }

  buscarCampo(nombre) {
    ////zzconsole.log(this.campos)

    let ignorados = [
      "_medidaAn",
      "_medida2An",
      "_medidaAl",
      "_medida2Al",
      "cantidad",
      "control_autocompletable"
    ];
    for (let i = 0; i < ignorados.length; i++) {
      if (ignorados[i] == nombre)
        return "";
    }
    for (var i = 0; i < this.campos.length; i++) {

      if (this.campos[i]["nombre"] == nombre) {

        return this.campos[i];
      }
    }
  }

  buscarCampoIndice(nombre) {
    ////zzconsole.log(this.campos)

    let ignorados = [
      "_medidaAn",
      "_medida2An",
      "_medidaAl",
      "_medida2Al",
      "cantidad",
      "control_autocompletable"
    ];
    for (let i = 0; i < ignorados.length; i++) {
      if (ignorados[i] == nombre)
        return "";
    }
    for (var i = 0; i < this.campos.length; i++) {

      if (this.campos[i]["nombre"] == nombre) {

        return i;
      }
    }
  }

  _ngOnInit() {
    this.metros = Array(100)["fill"](0, 0, 100)["map"]((x, i) => i);
    this.centimetros = Array(100)["fill"](0, 0, 100)["map"]((x, i) => i);
    setTimeout(() => {

      let elements = document.getElementsByClassName("enfoqueRebote");

      for (let x = 0; x < elements.length; x++) {
        //console.log("el eelmento")
        elements[x].addEventListener('DOMSubtreeModified', function () {
          elements[x].classList.add("rebotando");
          setTimeout(function () {
            elements[x].classList.remove("rebotando");

          }, 100);

        });
      }


    }, 3000);

  }

  armarParamsResumen() {
    let parte1 = this.detalle.producto_medible == "1" ? {
      "Tamaño": (this.ancho || 0) + "m x " + (this.alto || 1) + "m"
    } : {};

    /* for(let x=0;x<this.campos.length;x++){
      const _aux=this.campos[x];
      if(this.visible(_aux) && _aux && _aux.tipo!="DescuentoXVolumen" ){
        parte1[_aux["label"||""]]=
        (_aux.opciones[0].valor_selecionado);

      }
    }
 */
    let arrayData = [];
    for (let clave in this.productoForm.controls) {
      let etiqueta = "";
      this.campos.forEach((val, index) => {
        if (val.tipo == "Múltiple" && clave == val["nombre"]) {
          arrayData.push({
            nombre: val["label"],
            value: this.obtenerValoresdeCampoMultiplo(val["nombre"], index),
          });
          //alert(this.obtenerValoresdeCampoMultiplo(val["nombre"],index));
        } else if (clave == val["nombre"]) {
          arrayData.push(
            {
              nombre: val["label"],
              value: this.productoForm.controls[clave].value
            }
          );

        }


      });

    }

    for (let x = 0; x < arrayData.length; x++) {
      let _aux = arrayData[x];
      //parte1[_aux["label"||""]]=
      //(_aux.opciones[0].valor_selecionado);
      parte1[_aux["nombre" || ""]] = _aux["value"];
    }

    let resumen = {};
    if(this.adaptarParaImpresionDigital()){
      resumen["Piezas por tabloide"]=this.removerFlotante(this.calcularPiezas());
      resumen["Total de piezas"]=this.removerFlotante(parseFloat(this.calcularPiezas())*this.detalle.productCount);
    }

    if (this.detalle.cancelar_cantidad != "1") {

      resumen["CANTIDAD"] = this.cantidad || 1;
    }


    if (this.detalle.producto_medible == '1') {
      let subTotal = this.detalle.subtotal;
      resumen = Object.assign(resumen,
        {
          "SUBTOTAL": `$${parseFloat(subTotal).toFixed(2)}`
        }
      );

      if (this.detalle.medida_fija == "variante") {

        resumen["TOTAL M2"] = this.fixearCantidad(this.ancho * this.alto * (this.cantidad || 1)),
          resumen["PRECIO U. " + this.detalle.unidad_medida + "2"] = this.precioUnitario;
      }

    }

    return { general: resumen, campos: parte1 }
  }


  nextStepper(): void {
    let indexSeleccionado = this.stepper.selectedIndex;

    if (indexSeleccionado == 0) {
      this.arrayConfirmaciones[indexSeleccionado]["params"]["data"]["resumen"] = this.armarParamsResumen()

    }
    //let dialogos=_CargaDisenioDialog_|_DireccionDialog_;
    const dialogRef = this.dialog.open(
      this.arrayConfirmaciones[indexSeleccionado].type,
      this.arrayConfirmaciones[indexSeleccionado].params
    );

    this.subscriptions.push(
      this.subsDialog = dialogRef.afterClosed().subscribe(this.arrayConfirmaciones[0].subscribe)
    );
  }




  /**
   * DEMO VARIABLES
  */
  _medidaAn: number = 1;
  _medida2An: number = null;
  _medidaAl: number = 1;
  _medida2Al: number = null;


  metros: number[];
  centimetros: number[];
  agenciaSeleccionada: any = "";
  agenciaSeleccionada1: any = "";
  agenciaSeleccionada2: any = "";
  agenciaSeleccionada3: any = "";
  agenciaSeleccionada4: any = "";
  public imagenesDemo: any[] = [
    { src: "/assets/images/img1_instalacion.png", ref: "Mural de fachada" },
    { src: "/assets/images/img2_instalacion.png", ref: "Puerta de acceso a taller" },
    { src: "/assets/images/img3_instalacion.png", ref: "Mural punto de venta" },
  ];

  tamBase: number = 300;
  tamBaseX: number = 0;
  tamBaseY: number = 0;

  ancho: number = 1;//Metros
  alto: number = 1;//Metros
  proporcion: number = .5;

  color1: string;
  color2: string;
  color3: string;
  cambiaMedida() {
    this._medidaAn  =parseFloat(this._medidaAn+"");
    this._medida2An =parseFloat(this._medida2An+"");
    this._medidaAl  =parseFloat(this._medidaAl+"");
    this._medida2Al =parseFloat(this._medida2Al+"");

    this.ancho = (this._medidaAn + (this._medida2An / 100) );
    this.alto = (this._medidaAl + (this._medida2Al / 100) );
    this.proporcion = this.ancho / (this.ancho + this.alto);
    this.calcularAnchoFN();
    this.calcularAltoFN();
    this.calcularPrecio()
    this.changeDetectRef.detectChanges();
  }

  unidadMedida: any = [
    { tipo: 1, texto: "metros", abrev: "m" },
    { tipo: 2, texto: "centimetros", abrev: "cm" },
  ]
  unidadMedidaValue: number = 1;

  calculoAncho: string = (this.tamBase * .5) + "px";
  calculoAlto: string = (this.tamBase * .5) + "px";
  calculoAnchoX2: string = (this.tamBase * 1) + "px";
  calculoAltoX2: string = (this.tamBase * 1) + "px";

  calcularAnchoFN(): void {
    this.calculoAncho = this.tamBase * (this.ancho / (this.ancho + this.alto)) + "px";
    this.calculoAnchoX2 = this.tamBase * (this.ancho / (this.ancho + this.alto)) * 2 + "px";
  }

  obtenerValor(val) {
    return parseFloat((this[val] + "").replace(/px/g, ''))
  }

  calcularAltoFN(): void {
    this.calculoAlto = this.tamBase * (this.alto / (this.ancho + this.alto)) + "px";
    this.calculoAltoX2 = this.tamBase * (this.alto / (this.ancho + this.alto)) * 2 + "px";
  }

  keyUpAncho() {
    this.changeDetectRef.detectChanges();
    //zzconsole.log(this.ancho)
  }
  fAncho: boolean = false;
  focusAncho(): void {
    this.fAncho = true;
  }
  blurAncho(): void {
    this.fAncho = false;
  }

  keyUpAlto() {
    this.changeDetectRef.detectChanges();
    //zzconsole.log(this.ancho)
  }
  fAlto: boolean = false;
  focusAlto(): void {
    this.fAlto = true;
  }
  blurAlto(): void {
    this.fAlto = false;
  }

  public faceRotacion = 1;

  cambiarRotacion() {
    this.faceRotacion = ++this.faceRotacion % 2;
  }

  borderCuadrado(opcion: string): string {
    return this[opcion] ? '5px solid red' : '2px solid black';
  }


  archivoRef: any;
  public uploadImageDireccion(image) {
    this.archivoRef = image;
  }

  archivoRefDisenioImagen: any;
  public uploadDisenioImagen(image) {
    this.archivoRefDisenioImagen = image.target.files.item(0);
    const params = {
      file: this.archivoRefDisenioImagen,
      cliente_id: this.cliente_id
    };

    this.appState$.dispatch(new SubirImagenDisenioAction(params))
  }

  public subirDisenioImagen() {
    document.getElementById('fileDisenioImagen').click();
    //fileDisenioImagen
  }

  public clickAdjuntos() {
    document.getElementById('adjuntos').click();
  }
  public clickAdjuntoTexto() {
    document.getElementById('textoAdjunto').click();
  }
  public clickAdjuntoLogo() {
    document.getElementById('logoAdjunto').click();
  }
  public clickAdjuntosDisenio() {
    document.getElementById('referenciaAdjunto').click();
  }

  nArchivos: number = 0;
  adjuntoDiseno: any;
  public anadirAdjuntoDiseno(_adjunto) {
    this.adjuntoDiseno = _adjunto.target.files.item(0);
    const params = {
      file: this.adjuntoDiseno,
      cliente_id: this.cliente_id
    };
    //console.log(this.adjuntoDiseno);

    this.appState$.dispatch(new AgregarAdjuntoImagenDisenioAction(params))
  }

  public anadirTextoAdjuntoDiseno(_adjunto) {
    this.adjuntoDiseno = _adjunto.target.files.item(0);
    const params = {
      file: this.adjuntoDiseno,
      cliente_id: this.cliente_id
    };
    //console.log(this.adjuntoDiseno);

    this.appState$.dispatch(new AgregarTextoDisenioAction(params))
  }

  eleccionLogotipo = 0;

  public anadirLogoAdjuntoDiseno(_adjunto) {
    this.adjuntoDiseno = _adjunto.target.files.item(0);
    const params = {
      file: this.adjuntoDiseno,
      cliente_id: this.cliente_id
    };
    //console.log(this.adjuntoDiseno);

    this.appState$.dispatch(new AgregarLogoDisenioAction(params))
  }

  public anadirRefAdjuntoDiseno(_adjunto) {
    this.adjuntoDiseno = _adjunto.target.files.item(0);
    const params = {
      file: this.adjuntoDiseno,
      cliente_id: this.cliente_id
    };
    //console.log(this.adjuntoDiseno);

    this.appState$.dispatch(new AgregarReferenciaDisenioAction(params));
  }

  public reducirNombreArchivo(texto: string): string {
    if (texto.length > 17) {
      var regex = new RegExp(texto.substr(7, texto.length - 14), "g")
      return texto.replace(regex, "....")
    }

    return texto;
  }

  eliminarAdjuntoIndice(index) {
    this.adjuntoDiseno = null;
    this.appState$.dispatch(new EliminarAdjuntoDisenioAction(index))
  }

  obtenerExtencion(nombreArchivo) {
    var extencion = "";
    if (nombreArchivo.search(/\.ai$/g) != -1)
      return "ai.png";
    if (nombreArchivo.search(/\.(doc|docx)$/g) != -1)
      return "doc.png";
    if (nombreArchivo.search(/\.file$/g) != -1)
      return "file.png";
    if (nombreArchivo.search(/\.(jpg|jpeg)$/g) != -1)
      return "jpg.png";
    if (nombreArchivo.search(/\.pdf$/g) != -1)
      return "pdf.png";
    if (nombreArchivo.search(/\.png$/g) != -1)
      return "png.png";
    if (nombreArchivo.search(/\.psd$/g) != -1)
      return "psd.png";
    if (nombreArchivo.search(/\.svg$/g) != -1)
      return "svg.png";
    if (nombreArchivo.search(/\.txt$/g) != -1)
      return "txt.png";
    if (nombreArchivo.search(/\.zip$/g) != -1)
      return "zip.png";

    else
      return "file.png"
  }

  arrayConfirmaciones = [
    {
      scope: {},
      class: "ConfirmacionDialog",
      type: ConfirmacionDialog,
      'subscribe': (result) => {
        if (result) this.stepper.next();
      },
      params: {
        width: '520px',
        height: '450px',
        data: { select: 0 },

      },
    },
    {
      scope: {},
      class: "ConfirmacionDialog",
      type: ConfirmacionDialog,
      'subscribe': (result) => {
        if (result) this.stepper.next();
      },
      params: {
        width: '520px',
        height: '200px',
        data: { select: 0 }
      },
    },
    {
      scope: {},
      class: "ConfirmacionDialog",
      type: ConfirmacionDialog,
      'subscribe': (result) => {
        //console.log("saliendo!!!")
        if (result) this.agregarAlCarrito();
      },
      params: {
        width: '520px',
        height: '200px',
        data: { select: 2 }
      },
    }
  ];

  


  public agregarAlCarrito() {
    
    //console.log("agregando al che carrito!!",this.detalle.subtotal/this.detalle.productCount)
    this.detalle;
    const param: any = {
      campos: this.productoForm.getRawValue()
    };
    param.totalOptions = parseFloat(this.precioUnitario);
    this.detalle.productCount = Number.isNaN(parseFloat(this.cantidad.toString()))? 1 : parseFloat(this.cantidad.toString())
    this.detalle._totalOptions = this.detalle.subtotal / this.detalle.productCount;
    this.detalle.totalOptions = this.detalle.subtotal / this.detalle.productCount;
    
    if (this.opcionDisenioEscojida && this.detalle.tiene_disenio == '1') {
      //inserto el concepto del producto de diseño
      this.detalle["disenio"] = {
        disenio: true,
        name: 'Diseño de ' + this.detalle["name"],
        productCount: 1,
        productId: 563,//producto id especial para diseño
        _totalOptions: this.obtenerPrecioDisenio(),
        totalOptions: this.obtenerPrecioDisenio(),
        price: this.obtenerPrecioDisenio() || "0.00",
        dataFormulario: [{ nombre: "Descripción", "value": 'Diseño de ' + this.detalle["name"] }],
        opcionDisenioEscojida: this.opcionDisenioEscojida,
      }
    }
    //console.log("data producto medidas",this.productoForm.getRawValue());
    //console.log("data diseño producto",this.disenoForm.getRawValue());

    let arrayData = [];
    for (let clave in this.productoForm.controls) {
      let etiqueta = "";
      this.campos.forEach((val, index) => {
        if (val.tipo == "Múltiple" && clave == val["nombre"]) {
          arrayData.push({
            nombre: val["label"],
            value: this.obtenerValoresdeCampoMultiplo(val["nombre"], index),
          });
          //alert(this.obtenerValoresdeCampoMultiplo(val["nombre"],index));
        } else if (clave == val["nombre"]) {
          arrayData.push(
            {
              nombre: val["label"],
              value: this.productoForm.controls[clave].value || 'Sin espesificar'
            }
          );

        }


      });

    }

    if (this.detalle.medida_fija == 'variable' && this.detalle.producto_medible && this.detalle.medida_fija == "variable") {
      arrayData.push(
        {
          nombre: "Medidas",
          value: `Largo ${param.campos['_medidaAn']}.${param.campos['_medida2An']} ${this.detalle.unidad_medida} x
               Alto ${param.campos['_medidaAl']}.${param.campos['_medida2Al']} ${this.detalle.unidad_medida}`,
        }
      );
    }

    if(this.adaptarParaImpresionDigital()){
      arrayData.push(
        {
          nombre:"Piezas por tabloide",
          value:this.calcularPiezas(),

        }
      );
      arrayData.push(
        {
          nombre:"Total de piezas",
          value:(parseFloat(this.calcularPiezas())*this.detalle.productCount),

        }
      )
    }
    //this.detalle["dataFormulario"]=this.productoForm.getRawValue();
    this.detalle["dataFormulario"] = arrayData;
    this.detalle.price = this.detalle.subtotal / this.detalle.productCount;
    //alert(this.detalle.price)
    //console.log("El detalle!:",this.detalle);
    //console.log("El formulario todo",this.productoForm.getRawValue());
    if(!this.dataFormulario){ 
      this.controlSandbox.addItemsToCart(this.detalle, param);
      this.router.navigate(['/cart']);
    }else if(this.dataFormulario && !this.idOrden){ 
      this.controlSandbox.addItemsToCartIndex(this.detalle, param,this.indiceCarrito);
      document.location.reload();
    }else if(this.dataFormulario && this.idOrden ){
    
      this.cotizacion.emit({
        detalle:this.detalle,
        params:param

      });
    }

  }
  precioBaseDinamico: string = "1";
  editarDinamico: boolean = false;
  editarPrecioDinamico(param: boolean) {

    if (param) {
      setTimeout(() => this.precioDinamicoElement.nativeElement.focus(), 0);

    } else {
      this.cambiaPrecioDinamico();
    }

    this.editarDinamico = param;
  }

  cambiaPrecioDinamico() {
    this.precioBaseDinamico = this.precioBaseDinamico.replace(/[^\d\.?\d*]/g, '');
    try {

      console.log("cambia precio dinamico");
      console.log("preciodinamico:", this.precioBaseDinamico)
      this.detalle.precio = parseFloat(this.precioBaseDinamico) || 0;
      this.detalle.price = this.detalle.precio;
      this.precioOriginal = this.detalle.precio;
      this.precioUnitario = this.detalle.precio;
      this.detalle.subtotal = this.detalle.precio * this.cantidad;
      this.calcularPrecio();
    } catch (e) {
      console.log("ocurre un errror")
      this.detalle.precio = 0;
      this.detalle.price = this.detalle.precio;
      this.precioOriginal = this.detalle.precio;
      this.precioUnitario = this.detalle.precio;
      this.detalle.subtotal = this.detalle.precio * this.cantidad;
      this.calcularPrecio();
    }
  }

  obtenerPrecioDisenio() {
    switch (this.opcionDisenioEscojida) {
      case 1: return 0; break;
      case 2: return 300; break;
      case 3: return 600; break;
        defailt: return 0;
    }
  }

  /**
   * Esta funcion evita que las facturas den más de 2 decimales
   * @param subtotal 
   * @returns 
   */

  parseoParaFactura(subtotal:any=1):number{
    try {
      let auxSubtotal=subtotal;
      let _subtotal=subtotal.toString();
      let cantidadU=parseFloat(this.detalle["productCount"]||"1");
      if(this.detalle["nombre_unidad"]=="millar")
        cantidadU*=1000;
      else if(this.detalle["nombre_unidad"]=="centena")
        cantidadU*=100;

      let res=(parseFloat(_subtotal)/cantidadU).toFixed(4);
      let indexPunto=-1;
      let indexPunto2;
      console.log(`parseFloat(${_subtotal})/${cantidadU})`);
      if((indexPunto=res.indexOf("."))>-1){
        let decimales=parseFloat(res.substr(indexPunto+1)).toFixed(4);
        if(decimales.length<=2){
          console.log("la divicion solo 2 decimales")
          return parseFloat(subtotal);
        }
        else{
          indexPunto2=auxSubtotal.toString().indexOf(".");
          console.log("tratando de redondear la factura")
          let redondeo=Math.ceil(parseFloat(decimales)/100)/100;
          if(indexPunto2<0){
            
            res=(`${auxSubtotal.toString()}.${redondeo}`);
          }else{
            res=(`${auxSubtotal.toString().substr(0,indexPunto2)}.${redondeo}`);
          }
          let __subtotal=redondeo*cantidadU;
          res=`${__subtotal}`;
          console.log(`subtotal:: ${auxSubtotal} 2 ${res}`);
          return parseFloat(res);
        }
      }else{
        console.log(`la division da entera ${subtotal}`);
        return parseFloat(subtotal);
      }
      
      
    } catch (error) {
      console.log(error);
      console.log(`4 ${subtotal}`);
      return subtotal; 
    }

  }


}





class ContactoModelo {
  email: string;
  firstName: string;
  grupo_id: string;
  id: string;
  lastName: string;
}